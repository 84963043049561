import {createSelector} from "reselect";
import {LoadingState} from "constants/redux";
import {convertErrorIntoErrorMessage} from "../helpers";
import {AppState} from "redux/AppState";
import {AuthUserRole} from "../authTypes";

const getAuthState = (state: AppState) => state.auth;
const getLoginLoadingState = createSelector(getAuthState, (state) => state.loginLoadingState);
const getRegistrationLoadingState = createSelector(getAuthState, (state) => state.registrationLoadingState);
const getError = createSelector(getAuthState, (state) => state.error);
export const getAuthUser = createSelector(getAuthState, (state) => state.user);

/**
 * Returns true if portal is trying to logging in the user
 */
export const isLoggingIn = createSelector(getLoginLoadingState, (state) => state === LoadingState.LOADING);
/**
 * Returns true if portal is trying to logging in the user
 */
export const isRegistering = createSelector(getRegistrationLoadingState, (state) => state === LoadingState.LOADING);
/**
 * Returns error message key by evaluating `error` object from state
 */
export const getAuthErrorMessage = (state: AppState) => {
	return convertErrorIntoErrorMessage(getError(state));
};

export const getOwnId = createSelector(getAuthState, (state) => (state.user ? state.user.id : -1));

/**
 * Check whether user is logged in.
 */
export const isLoggedIn = createSelector(getAuthState, (state) => !!state.user);

export const getUserOrganization = (state: AppState) => state.auth.user?.organization;

export const isAcceptedToOrganization = (state: AppState) => getUserOrganization(state)?.isAccepted;

/**
 * Get user's role. Usually to differentiate between client and therapist.
 */
export const getUserRole = (state: AppState) => state.auth.user?.role;
/** Returns true if logged in user is a client */
export const isClient = (state: AppState) => getUserRole(state) === AuthUserRole.CLIENT;
/** Returns true if logged in user is a therapist */
export const isTherapist = (state: AppState): boolean => getUserRole(state) === AuthUserRole.THERAPIST;
/** Returns true if logged in user is a researcher */
export const isResearcher = (state: AppState) => getUserRole(state) === AuthUserRole.RESEARCHER;
/** Returns true if logged in user is a therapist or researcher */
export const isTherapistOrResearcher = (state: AppState) => isTherapist(state) || isResearcher(state);
/** Returns true if logged in user is a therapist and accepted to the organization */
export const isAcceptedTherapist = (state: AppState) => isTherapist(state) && isAcceptedToOrganization(state);

export const getChangePasswordLoadingState = (state: AppState) => getAuthState(state).changePasswordLoadingState;

export const isLoginBlocked = (state: AppState) => getAuthState(state).isLoginBlocked;
export const getLoginTwoFAState = (state: AppState) => getAuthState(state).loginTwoFA;
export const getLoginTwoFACodeAttemptCount = (state: AppState) => getLoginTwoFAState(state).codeAttemptCount;
export const getLoginTwoFARecoveryCodeAttemptCount = (state: AppState) =>
	getLoginTwoFAState(state).recoveryCodeAttemptCount;

export const getPasswordResetTwoFAState = (state: AppState) => getAuthState(state).passwordResetTwoFA;
export const getPasswordResetTwoFACodeAttemptCount = (state: AppState) =>
	getPasswordResetTwoFAState(state).codeAttemptCount;
export const getPasswordResetTwoFARecoveryCodeAttemptCount = (state: AppState) =>
	getPasswordResetTwoFAState(state).recoveryCodeAttemptCount;

export const getEmailVerificationStatusFetchState = (state: AppState) =>
	getAuthState(state).emailVerificationLoadingState;

export const getEmailVerificationStatus = (state: AppState) => getAuthState(state).user?.account?.emailVerified;
